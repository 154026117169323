<template>
  <el-card>
    <common-user-center-title :title="'收货地址'"></common-user-center-title>
    <el-button type="primary" @click="addUserAddress">新增地址</el-button>
    <el-table :data="addressList" border stripe>
      <el-table-column type="index"/>
      <el-table-column label="收货人姓名" prop="contact_name"/>
      <el-table-column label="收货人手机" width="110" prop="contact_phone"/>
      <el-table-column label="收货省" prop="province"/>
      <el-table-column label="收货市" prop="city"/>
      <el-table-column label="收货区/县" prop="district"/>
      <el-table-column label="详细地址" prop="address"/>
      <el-table-column label="默认地址" width="80" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.is_default === 1" type="success">默认</el-tag>
          <el-tag v-else type="danger">非默认</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="120">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit"
            @click="editUserAddress(scope.row)"
          />
          <el-button
            size="mini"
            type="danger"
            icon="el-icon-delete"
            @click="deleteUserAddress(scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :title="addressFormStatus ? '新增地址' : '编辑地址'"
               :visible.sync="addressDialogVisible" width="50%"
               @close="addressDialogClosed">
      <el-form :model="addressForm"
               :rules="addressFormRules"
               ref="addressFormRef" label-width="120px">
        <el-form-item label="收货人名称" prop="contact_name">
          <el-input v-model="addressForm.contact_name"/>
        </el-form-item>
        <el-form-item label="收货人手机号" prop="contact_phone">
          <el-input v-model="addressForm.contact_phone"/>
        </el-form-item>
        <el-form-item label="省市区" prop="area">
          <el-cascader
            v-model="addressForm.area"
            :options="areaCascaderList"
            :props="areaCascaderProps"
            :placeholder="addressForm.province === ''? '请选择地址': addressForm.province+' / '+addressForm.city+' / '+addressForm.district"
            clearable
            @change="areaCascaderHandleChange">
          </el-cascader>
        </el-form-item>
        <el-form-item label="收货地址" prop="address">
          <el-input v-model="addressForm.address"/>
        </el-form-item>
        <el-form-item label="设为默认地址" prop="isDefault">
          <el-radio-group v-model="addressForm.is_default">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <template #footer>
                  <span class="dialog-footer">
                    <el-button @click="addressDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="addressSubmit">确 定</el-button>
                  </span>
      </template>
    </el-dialog>
  </el-card>
</template>

<script>
import commonUserCenterTitle from '@/components/common/CommonUserCenterTitle.vue'
import {
  getUserReceivingAddressUrl, deleteUserReceivingAddressUrl,
  editUserReceivingAddressUrl, getAllAddressUrl
} from '@/api/index'

export default {
  name: 'Address',
  components: {
    commonUserCenterTitle
  },
  data () {
    return {
      addressList: [],
      addressDialogVisible: false,
      addressForm: {
        contact_name: '',
        province: '',
        contact_phone: '',
        province_id: '',
        city_id: '',
        district_id: '',
        address: '',
        is_default: 1,
        address_id:''
      },
      addressFormRules: {
        contact_name: [
          { required: true, message: '请输入收货人名称', trigger: 'blur' }
        ],
        contact_phone: [
          { required: true, message: '请输入收货人手机', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '请输入详细地址', trigger: 'blur' }
        ],
      },
      addressFormStatus: false,
      // 地址级联选择器配置
      areaCascaderProps: {
        expandTrigger: 'hover',
        children: 'district',
        label: 'name',
        value: 'id'
      },
      // 地址列表
      areaCascaderList: [],
      // 修改保存下来的地址id
      areaCascaderSelectId: []
    }
  },
  created () {
    this.initData()
  },
  methods: {
    initData () {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.getAddressList()
      this.getAreaList()
      setTimeout(()=>{
        this.changeLoading(loading)
      },300)
    },
    // 关闭loading弹窗
    changeLoading(loading){
      loading.close();
    },
    // 获取用户地址列表
    async getAddressList () {
      const { data: res } = await this.$http.get(getUserReceivingAddressUrl)
      if (res.code !== 200) return this.$message.error(res.msg)
      console.log(res)
      this.addressList = res.data
    },
    // 获取省市区
    async getAreaList () {
      const { data: res } = await this.$http.get(getAllAddressUrl)
      if (res.code !== 200) return this.$message.error(res.msg)
      console.log(res)
      // 统一级联选择器
      res.data.province.forEach(item => {
        item.district = item.city
      })
      this.areaCascaderList = res.data.province
    },
    // 修改省市区地址级联选择器
    areaCascaderHandleChange (value) {
      this.areaCascaderSelectId = value
    },
    // 新增地址按钮
    addUserAddress () {
      this.$nextTick(() => {
        this.addressDialogVisible = true
        this.addressFormStatus = true
      })
    },
    // 用户地址编辑
    editUserAddress (userAddressInfo) {
      console.log(userAddressInfo)
      this.$nextTick(() => {
        this.addressForm = userAddressInfo
        this.addressFormStatus = false
        this.addressDialogVisible = true
        this.addressForm.address_id = userAddressInfo.id
      })
    },
    // 用户地址删除
    async deleteUserAddress (userAddressInfo) {
      this.$confirm('此操作将永久删除该收货地址, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { data: res } = await this.$http.get(deleteUserReceivingAddressUrl, { params: { address_id: userAddressInfo.id } })
        console.log(res)
        if (res.code !== 200) {
          this.$message.error('删除地址失败' + res.msg)
          return false
        }
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
        this.getAddressList()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 新增、编辑用户地址弹窗关闭事件
    addressDialogClosed () {
      this.areaCascaderSelectId = []
      this.addressForm = {
        contact_name: '',
        province: '',
        contact_phone: '',
        province_id: '',
        city_id: '',
        district_id: '',
        address: '',
        is_default: 1,
      }
    },
    addressSubmit () {
      this.$refs.addressFormRef.validate(async valid => {
        if (!valid) return this.$message.error('请完善必填项')
        console.log(editUserReceivingAddressUrl)
        if (this.areaCascaderSelectId.length > 0 && this.areaCascaderSelectId.length < 3) {
          // 省市区没有选择完整
          return this.$message.error('请选择完整的省市区')
        } else if (this.areaCascaderSelectId.length === 3) {
          this.addressForm.province_id = this.areaCascaderSelectId[0]
          this.addressForm.city_id = this.areaCascaderSelectId[1]
          this.addressForm.district_id = this.areaCascaderSelectId[2]
        }
        this.$delete(this.addressForm,'area')
        if(this.addressFormStatus){
          // 新增
          this.$delete(this.addressForm,'address_id')
          this.$delete(this.addressForm,'province')
          const { data: res } = await this.$http.post(editUserReceivingAddressUrl,this.addressForm)
          if (res.code !== 200) return this.$message.error(res.msg)
          console.log(res)
          this.$message.success('新增地址成功')
          this.addressDialogVisible = false
        }else {
          // 编辑
          const { data: res } = await this.$http.post(editUserReceivingAddressUrl,this.addressForm)
          if (res.code !== 200) return this.$message.error(res.msg)
          console.log(res)
          this.$message.success('修改地址成功')
          this.addressDialogVisible = false
        }
        console.log(this.addressForm)
        this.getAddressList()
      })

      console.log(editUserReceivingAddressUrl)

    },
  }
}
</script>

<style scoped>
  .el-table {
    margin-top: 20px;
  }

  .el-cascader {
    display: block;
  }
</style>
